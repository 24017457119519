<template>
    <!-- :title="isEdit?'编辑联单':'新增联单'" -->
    <el-dialog :close-on-click-modal="false" title="编辑联单" :visible.sync="show" width="550px" class="selfInputBox selfInputBoxIV">

        <div class="buttons buttonsII">
            <el-button type="primary" @click="">联单信息</el-button>
            <el-button class="base" type="primary" plain @click="">基本信息</el-button>
        </div>

        <div class="PopContentBox">

            <el-form v-if="formShow" ref="form" label-width="150px">

                <!-- <el-form-item label="唯一标识" prop="">
                <el-input v-model="orderInfo.name" placeholder="请输入唯一标识" />
            </el-form-item> -->

                <!-- <el-form-item label="医废类型">
                <el-select clearable v-model="orderInfo.item_id" placeholder="请选择医废类型" class="SelfSelect">
                    <el-option :label="item.name" :value="item.id" v-for="item in ItemTypeList"
                        :key="item.id"></el-option>
                </el-select>
            </el-form-item> -->

                <el-form-item label="医废类型">
                    <el-select clearable v-model="SelectionID" placeholder="请选择医废类型" class="SelfSelect">
                        <el-option :label="item.item_name" :value="item.item_id" v-for="item in ItemTypeList"
                            :key="item.item_id"></el-option>
                    </el-select>
                </el-form-item>

                <!-- <el-form-item label="所属医院">
                <el-select :disabled="!orderInfo.is_first" v-model="orderInfo.dep_id" placeholder="请选择所属医院"
                    class="SelfSelect">
                    <el-option :label="item.name" :value="item.id" v-for="item in depList" :key="item.id"></el-option>
                </el-select>
            </el-form-item> -->

                <el-form-item label="所属医院" prop="">
                    <el-input disabled v-model="hospital.dep_name" />
                </el-form-item>

                <!-- 包装类型：1包装袋、2利器盒 -->
                <el-form-item label="包装类型">
                    <!-- :disabled="!orderInfo.is_first" -->
                    <el-select disabled v-model="orderInfo.packing_id" placeholder="请选择包装类型" class="SelfSelect">
                        <el-option :label="'包装袋'" :value="'6'"></el-option>
                        <el-option :label="'利器盒'" :value="'5'"></el-option>


                        <!-- "packing_id": 1, //包装类型：、5利器盒 6包装袋 -->
                        <!-- <span v-if="scope.row.packing_id==5">
                            利器盒
                        </span>
                        <span v-if="scope.row.packing_id==6">
                            包装袋
                        </span> -->

                    </el-select>
                </el-form-item>


                <el-form-item label="总数量" prop="">
                    <el-input :disabled="!orderInfo.is_first" class="num" v-model="orderInfo.quantity"
                        placeholder="请输入总数量" />
                </el-form-item>

                <el-form-item label="总重量" prop="">
                    <el-input :disabled="!orderInfo.is_first" class="weight" v-model="orderInfo.weight"
                        placeholder="请输入总重量" />
                </el-form-item>

                <el-form-item label="本院科室数量" prop="">
                    <el-input :disabled="!orderInfo.is_first" class="num" v-model="orderInfo.level_quantity"
                        placeholder="请输入本院科室数量" />
                </el-form-item>

                <el-form-item label="本院科室重量" prop="">
                    <el-input :disabled="!orderInfo.is_first" class="weight" v-model="orderInfo.level_weight"
                        placeholder="请输入本院科室重量" />
                </el-form-item>

                <el-form-item label="本院辖区卫生室数量" prop="">
                    <el-input :disabled="!orderInfo.is_first" class="num" v-model="orderInfo.dep_quantity"
                        placeholder="请输入本院辖区卫生室数量" />
                </el-form-item>

                <el-form-item label="本院辖区卫生室重量" prop="">
                    <el-input :disabled="!orderInfo.is_first" class="weight" v-model="orderInfo.dep_weight"
                        placeholder="请输入本院辖区卫生室重量" />
                </el-form-item>

                <el-form-item label="本单位产生数量" prop="">
                    <el-input :disabled="!orderInfo.is_first" class="num" v-model="orderInfo.this_quantity"
                        placeholder="请输入本单位产生数量" />
                </el-form-item>

                <el-form-item label="本单位产生重量" prop="">
                    <el-input :disabled="!orderInfo.is_first" class="weight" v-model="orderInfo.this_weight"
                        placeholder="请输入本单位产生重量" />
                </el-form-item>

                <el-form-item label="下属民营数量" prop="">
                    <el-input :disabled="!orderInfo.is_first" class="num" v-model="orderInfo.tripartite_dep_number"
                        placeholder="请输入下属民营数量" />
                </el-form-item>

                <el-form-item label="第三方数量" prop="">
                    <el-input :disabled="!orderInfo.is_first" class="num" v-model="orderInfo.tripartite_quantity"
                        placeholder="请输入第三方数量" />
                </el-form-item>

                <el-form-item label="第三方重量" prop="">
                    <el-input :disabled="!orderInfo.is_first" class="weight" v-model="orderInfo.tripartite_weight"
                        placeholder="请输入第三方重量" />
                </el-form-item>

            </el-form>


            <div class="orderInfoChild" v-show="orderInfo.child != undefined && orderInfo.child.length>0">

                <span class="title">
                    三方单位接收 {{orderInfo.tripartite_quantity}}包，{{orderInfo.tripartite_weight}}Kg
                </span>

                <el-collapse class="collapse">
                    <el-collapse-item title="" v-for="item in orderInfo.child" :key="item.dep_id">

                        <template slot="title">
                            {{item.dep_name}}<i class="layui-icon layui-icon-ok status-icon-ok"></i>
                        </template>

                        <div>
                            <span>包数：{{item.quantity}}包</span>
                            <span>重量：{{item.weight}}KG</span>
                        </div>
                    </el-collapse-item>
                </el-collapse>

            </div>

        </div>


        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="save">保存</el-button>
                <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
            </div>
        </span>


    </el-dialog>
</template>

<script>
    import {
        mapState,
        mapMutations
    } from 'vuex'


    export default {
        // model: {
        //     prop: 'value',
        //     event: 'change'
        // },
        // props: {
        //     value: {
        //         type: Object,
        //         required: true
        //     }
        // },

        data() {
            return {
                show: false,
                formShow: false,
                state: true,

                orderInfo: {},
                SelectionID: "",
                hospital: {},

                ItemTypeList: [],
                depList: [],

                // isEdit: false

            }
        },

        computed: {
            ...mapState('m_item', ['LevelList', 'PositionList', 'DepList', 'HealthList', 'Province', 'City', 'District',
                'RoleList', 'Depcate', 'ItemType'
            ]),
        },

        watch: {
            show(value) {
                if (this.show) {

                    // this.getitemType()
                    // setTimeout(() => {
                    //     this.ItemTypeList = this.ItemType
                    // }, 1000)

                    this.getHazardousStay()


                    if (this.depList.length == 0) {
                        this.getdep()
                        setTimeout(() => {
                            this.depList = this.DepList
                        }, 1000)
                    }



                    this.hospital = {
                        dep_name: JSON.parse(localStorage.getItem("user")).dep_name,
                        dept_id: JSON.parse(localStorage.getItem("user")).dept_id
                    }

                } else {
                    // this.isEdit = false


                    this.orderInfo = {}
                    this.SelectionID = ""
                    this.hospital = {}
                }

            },

            SelectionID(value) {
                this.ItemTypeList.map(x => {
                    if (x.item_id == value) {
                        this.orderInfo = x

                        // 损伤性 利器盒
                        // 其他 包装袋

                        // <!-- 包装类型：1包装袋、2利器盒 -->
                        x.item_id == 3 ?
                            (this.orderInfo.packing_id = '5') :
                            (this.orderInfo.packing_id = '6')



                        // <!-- "packing_id": 1, //包装类型：、5利器盒 6包装袋 -->
                        // <span v-if="scope.row.packing_id==5">
                        //     利器盒
                        // </span>
                        // <span v-if="scope.row.packing_id==6">
                        //     包装袋
                        // </span>

                    }
                })
            }

        },
        methods: {

            getHazardousStay() {

                this.$http.get('/api/hazardous/stay').then((res) => {
                    if (res.code == 200) {

                        this.ItemTypeList = res.data

                    }
                }).catch((err) => {
                    console.log(err)
                });

            },

            open() {
                // this.orderInfo = {
                //     name: "",
                //     sort: ""
                // }

                this.formShow = true
                this.show = true
            },


            // edit(val) {

            //     this.open()

            //     this.isEdit = true

            //     // this.orderInfo = {
            //     //     ...val
            //     // }

            //     // this.$http.get('/api/admin/' + val.id).then((res) => {
            //     //     if (res.code == 200) {
            //     //         this.setInfo(res.data)
            //     //     }
            //     // }).catch((err) => {
            //     //     console.log(err)
            //     // });

            // },

            save() {

                this.orderInfo.dep_id = this.hospital.dept_id


                // if (this.isEdit) {

                //     this.$http.put('/api/depcate/' + this.orderInfo.id, this.info).then((res) => {

                //         console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                //         if (res.code != 200) { //请求错误
                //             this.$message.error(res.msg);

                //         }

                //         if (res.code == 200) {
                //             this.close()
                //         }

                //     }).catch((err) => {
                //         console.log(err)
                //     });

                // } else {

                this.$http.post('/api/hazardous', this.orderInfo).then((res) => {
                    // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                    if (res.code == 200) {
                        this.close()
                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);
                    }
                }).catch((err) => {
                    console.log(err)
                });

                // }

            },



            close() {
                this.show = false
                // this.$emit('refresh')

                this.$parent.getList();

                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            },




        }
    }
</script>

<style scoped lang="scss">
    /deep/.el-form-item__label {
        width: 150px !important;
        /* position: relative;
        left: 20px; */
    }

    /* .unit {
        position: relative;
    } */




    .buttonsII {
        // border: 1px green solid;
        // box-sizing: border-box;
        position: relative;
        top: -25px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .base {
            color: #768dfc;
            background: #fff;
            border-color: #768dfc;
        }

    }

    .PopContentBox {
        // border: 1px green solid;
        // box-sizing: border-box;
        max-height: 500px;
        overflow: auto;
    }





    .num::before {
        content: "包";
        position: absolute;
        right: 36px;
        z-index: 9;
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        color: #6A83FC;
        /* border: 1px green solid;
        box-sizing: border-box; */
    }

    .weight::before {
        content: "KG";
        position: absolute;
        right: 36px;
        z-index: 9;
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        color: #6A83FC;
        /* border: 1px green solid;
        box-sizing: border-box; */
    }



    .orderInfoChild {
        padding: 10px 50px;
        border-top: 2px #ccc dotted;
        box-sizing: border-box;


        .title {
            padding: 10px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #6A83FC;
            line-height: 20px;

            // border: 1px green solid;
            // box-sizing: border-box;

        }

        .collapse {
            border: 1px solid #dfdfdf;
            box-sizing: border-box;
            margin-top: 10px;

            border-radius: 6px;
            overflow: hidden;

            i {
                margin-left: 10px;
            }


            /deep/ .el-collapse-item__header {
                padding: 0 30px;
                position: relative;
            }

            /deep/ .el-collapse-item__arrow {
                position: absolute;
                left: 10px;
            }

            /deep/.el-icon-arrow-right:before {
                content: "\e791";
                color: #6A83FC;
            }




            /deep/ .el-collapse-item__header.is-active {
                border-bottom: 2px dotted #dfdfdf;
                box-sizing: border-box;

            }

            /deep/ .el-collapse-item__wrap {
                padding: 10px;
            }

            /deep/ .el-collapse-item__content {
                padding-bottom: 0px;

                div {
                    // border: 1px green solid;
                    // box-sizing: border-box;

                    // width: 70%;
                    // display: flex;
                    span {
                        margin-right: 20px;
                    }

                }
            }


        }

    }
</style>