<template>
    <!-- :title="isEdit?'编辑联单':'新增联单'" -->
    <el-dialog :close-on-click-modal="false" title="联单详情" :visible.sync="show" width="550px" class="selfInputBox selfInputBoxIV">

        <div class="PopContentBox">

            <div class="orderInfoChild orderInfoChildII">
                <span class="title">基础信息</span>

                <div class="collapse collapseII">
                    <span> 企业名称：{{orderInfo.dep_name}}</span>
                    <span> 医废类型：{{orderInfo.item_name}}</span>
                    <span> 生成日期：{{orderInfo.generate_day}}</span>
                    <span>创建人：{{orderInfo.create_name}}</span>
                    <span>创建时间：{{orderInfo.create_time}}</span>
                    <span>更新人： {{orderInfo.update_name}}</span>
                    <span>更新时间 {{orderInfo.update_time}}</span>
                    <span> 总数量： {{orderInfo.quantity}}</span>
                    <span> 总重量： {{orderInfo.weight}}</span>

                    <!-- 
                    <span>本院科室数量：{{orderInfo.level_quantity}}</span>
                    <span> 本院科室重量：{{orderInfo.level_weight}}</span>
                    <span> 本院辖区卫生室数量：{{orderInfo.dep_quantity}}</span>
                    <span> 本院辖区卫生室重量：{{orderInfo.dep_weight}}</span>
                    <span> 本单位产生数量：{{orderInfo.this_quantity}}</span>
                    <span> 本单位产生重量：{{orderInfo.this_weight}}</span>
                    <span> 第三方数量：{{orderInfo.tripartite_quantity}}</span>
                    <span> 第三方重量：{{orderInfo.tripartite_weight}}</span> -->

                </div>

            </div>


            <div class="orderInfoChild orderInfoChildII">
                <span class="title">本单位产生量 {{orderInfo.this_quantity}}包，{{orderInfo.this_weight}}Kg</span>
                <div class="collapse collapseII">
                    <span>本院科室数量：{{orderInfo.level_quantity}}</span>
                    <span> 本院科室重量：{{orderInfo.level_weight}}</span>
                    <span> 本院辖区卫生室数量：{{orderInfo.dep_quantity}}</span>
                    <span> 本院辖区卫生室重量：{{orderInfo.dep_weight}}</span>
                </div>
            </div>


            <div class="orderInfoChild" v-show="orderInfo.number != undefined && orderInfo.number.length>0">
                <span class="title">
                    三方单位接收 {{orderInfo.tripartite_quantity}}包，{{orderInfo.tripartite_weight}}Kg
                </span>
                <el-collapse class="collapse">
                    <el-collapse-item title="" v-for="item in orderInfo.number" :key="item.dep_id">
                        <template slot="title">
                            {{item.dep_name}}<i class="layui-icon layui-icon-ok status-icon-ok"></i>
                        </template>
                        <div>
                            <span>包数：{{item.quantity}}包</span>
                            <span>重量：{{item.weight}}KG</span>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>

        </div>

    </el-dialog>
</template>

<script>
    export default {
        // model: {
        //     prop: 'value',
        //     event: 'change'
        // },
        // props: {
        //     value: {
        //         type: Object,
        //         required: true
        //     }
        // },

        data() {
            return {
                show: false,
                formShow: false,
                state: true,

                orderInfo: {},

            }
        },


        watch: {
            show(value) {
                if (this.show) {

                } else {
                    this.orderInfo = {}
                }
            },

        },
        methods: {

            open() {
                this.formShow = true
                this.show = true
            },

            info(row) {

                this.$http.get('/api/hazardous/' + row.id).then((res) => {
                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);
                    }
                    if (res.code == 200) {

                        this.orderInfo = res.data

                        let a = JSON.parse(localStorage.getItem("user")).dept_id

                        this.orderInfo.number = this.orderInfo.number.filter(x => {
                            return x.dep_id != a
                        })

                        this.open()
                    }


                }).catch((err) => {
                    console.log(err)
                });

            },



            close() {
                this.show = false
                // this.$emit('refresh')

                this.$parent.getList();

                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            },




        }
    }
</script>

<style scoped lang="scss">
    .PopContentBox {
        // border: 1px green solid;
        // box-sizing: border-box;
        max-height: 500px;
        overflow: auto;
    }





    /deep/.el-dialog__body {
        padding: 0px 20px 30px 20px;
    }

    .orderInfoChildII {

        // border: none !important;

        // border: 1px green solid;
        // box-sizing: border-box;
    }

    .orderInfoChild {
        padding: 10px 50px;
        // border-top: 2px #ccc dotted;
        // box-sizing: border-box;

        // // border: 1px green solid;
        // box-sizing: border-box;


        .title {
            padding: 10px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #6A83FC;
            line-height: 20px;

            // border: 1px green solid;
            // box-sizing: border-box;

        }

        .collapseII {

            padding: 10px;

            span {
                // border: 1px green solid;
                // box-sizing: border-box;

                display: block;

            }
        }


        .collapse {
            border: 1px solid #dfdfdf;
            box-sizing: border-box;
            margin-top: 10px;

            border-radius: 6px;
            overflow: hidden;

            i {
                margin-left: 10px;
            }


            /deep/ .el-collapse-item__header {
                padding: 0 30px;
                position: relative;
            }

            /deep/ .el-collapse-item__arrow {
                position: absolute;
                left: 10px;
            }

            /deep/.el-icon-arrow-right:before {
                content: "\e791";
                color: #6A83FC;
            }


            /deep/ .el-collapse-item__header.is-active {
                border-bottom: 2px dotted #dfdfdf;
                box-sizing: border-box;

            }

            /deep/ .el-collapse-item__wrap {
                padding: 10px;
            }

            /deep/ .el-collapse-item__content {
                padding-bottom: 0px;

                div {
                    // border: 1px green solid;
                    // box-sizing: border-box;

                    // width: 70%;
                    // display: flex;
                    span {
                        margin-right: 20px;
                    }

                }
            }


        }

    }
</style>